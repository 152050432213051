import axios from "axios";
import { BASE_URL, getOrganizationInfoUrl } from "../shared/constants/api";

interface GetLoanInfoParams {
    callBack: (param: any) => any;
};

export const getOrganizationInfo = async ({ callBack }: GetLoanInfoParams) => {
    try {
        const { data } = await axios.get(`${BASE_URL}${getOrganizationInfoUrl}`);
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};
