import axios from "axios";
import { BASE_URL, getLoanUrl, COMMON_HEADERS } from "../shared/constants/api";

interface getLoanSimulatorDataParams {
    plan: string;
    installments: number;
    amount: number;
    callBack: (param: any) => any;
};

export const getLoanSimulatorData = async ({ plan, installments, amount, callBack }: getLoanSimulatorDataParams) => {
    const loanSimulatorData = new FormData();
    loanSimulatorData.append('SimularCred', plan);
    loanSimulatorData.append('cuotas', installments.toString());
    loanSimulatorData.append('monto', amount.toString());
    try {
        const { data } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getLoanUrl}`,
                data: loanSimulatorData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};
