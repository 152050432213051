import React from 'react';
import { emailRegex, namesRegex, numbersRegex, streetNameRegex } from '../../shared/constants/regex';
import { NewCustomerColumn } from './styles';
import { Form, Modal } from 'antd';
import { createNewCustomer } from '../../services/customer';
import FooterButtons from './footerButtons';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { Profession } from '../../provider/types';
import { GrippSelect, GrippInput } from '../../styles/global';

const { Option } = GrippSelect;

const NewCustomer = ({ isMobile, isOpen, onSubmit, onCancel, newCustomer }: any): JSX.Element => {
  const { orgInfo } = React.useContext(GrippUIContext);
  const [newCustomerForm] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const { sendNotification } = React.useContext(GrippUIContext);
  const [professionsList, setProfessionsList] = React.useState<Profession[]>([]);
  
  const values = Form.useWatch([], newCustomerForm);

  React.useEffect(()=>{
    if(newCustomer){
      newCustomerForm?.setFieldsValue(newCustomer);
    }
  }, [newCustomer]);

  React.useEffect(() => {
    newCustomerForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [values]);


  const callBackFn = (response: any) => {
    setIsLoading(false);
    if (response.IdCliente) {
      onSubmit(response);
    } else {
      sendNotification({ title: 'Error', description: 'Hubo un error al crear el nuevo cliente, espere un momento e intentelo nuevamente.', type: 'error' });
    };
  };

  const handleSubmit = () => {
    setIsLoading(true);
    createNewCustomer({ customerData: newCustomerForm.getFieldsValue(), callBack: callBackFn });
  };

  React.useEffect(() => {
    if (orgInfo?.professions) {
      setProfessionsList(orgInfo?.professions);
    }
  }, [orgInfo]);

  return (
    <Modal
      title="Nuevo cliente"
      open={isOpen}
      onOk={onSubmit}
      onCancel={onCancel}
      width={1000}
      destroyOnClose
      footer={
        <FooterButtons
          isLoading={isLoading}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          isValid={isFormValid}
        />
      }
    >
      <Form
        name="newCustomerForm"
        layout="vertical"
        form={newCustomerForm}
        disabled={isLoading}
      >
        <NewCustomerColumn isMobile={isMobile}>
          <Form.Item
            label="DNI"
            name="NumeroDocumento"
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
            rules={[
              { required: true, message: 'Número de identificacion inválido' },
              { pattern: numbersRegex(), message: 'Número de identificacion inválido' }
            ]}
          >
            <GrippInput
              min={0}
              placeholder="Ingrese su numero de identificacion"
              type="number"
            />
          </Form.Item>
          <Form.Item
            label="Nombre completo"
            name="Nombre"
            rules={[
              { required: true, message: 'Nombre inválido' },
              { pattern: namesRegex(), message: 'Nombre inválido' }
            ]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su nombre completo" />
          </Form.Item>
        </NewCustomerColumn>
        <NewCustomerColumn isMobile={isMobile}>
          <Form.Item
            label="Telefono"
            name="Particular"
            rules={[
              { required: true, message: 'Teléfono inválido' },
              { pattern: numbersRegex(), message: 'Teléfono inválido' }
            ]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su telefono" type="phone" />
          </Form.Item>
          <Form.Item
            label="Celular"
            name="Celular"
            rules={[
              { required: true, message: 'Celular inválido' },
              { pattern: numbersRegex(), message: 'Celular inválido' }
            ]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su celular" type="phone" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="Mail"
            rules={[
              { required: true, message: 'Email inválido' },
              { pattern: emailRegex(), message: 'Email inválido' }
            ]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su email" type="email" />
          </Form.Item>
        </NewCustomerColumn>
        <NewCustomerColumn isMobile={isMobile}>
          <Form.Item
            label="Calle"
            name="DomicilioCalle"
            rules={[
              { required: true, message: 'Calle inválida' },
              { pattern: streetNameRegex(), message: 'Calle inválida' }
            ]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su calle" />
          </Form.Item>
          <Form.Item
            label="Número"
            name="DomicilioNumero"
            rules={[{ required: true }]}
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su numero" type="number" />
          </Form.Item>
        </NewCustomerColumn>
        <NewCustomerColumn isMobile={isMobile}>
          <Form.Item
            label="Dpto"
            name="DomicilioDpto"
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su dpto" />
          </Form.Item>
          <Form.Item
            label="Barrio"
            name="Barrio"
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su barrio" />
          </Form.Item>
        </NewCustomerColumn>
        <NewCustomerColumn isMobile={isMobile}>
          <Form.Item
            label="Zona"
            name="Zona"
            style={{ display: 'inline-block', margin: '0 16px', width: "100%" }}
          >
            <GrippInput placeholder="Ingrese su zona" />
          </Form.Item>
          <Form.Item name="Profesion" label="Profesión" rules={[
          { required: true, message: 'Por favor ingrese una profesión' },
        ]}>
          <GrippSelect
            placeholder="Por favor ingrese una profesión"
            allowClear
            onChange={(value)=> {
              newCustomerForm.setFieldValue('TXProfesion', professionsList.find((e)=> e.Codigo === value)?.Detalle);
            }}
          >
            {professionsList.map((eachProfession: any, index: number) => (
              <React.Fragment key={`profession-${eachProfession.Codigo}`}>
                <Option value={eachProfession.Codigo}>{eachProfession.Detalle}</Option>
              </React.Fragment>
            ))}
          </GrippSelect>
        </Form.Item>
        </NewCustomerColumn>
      </Form>
    </Modal>
  );
};

export default NewCustomer;