import React from 'react';
import { CustomerLoanInformationRow } from '../../pages/Loan/styles';
import { Card, Form } from 'antd';
import { CreditRequestFormProps } from '../../pages/Loan/types';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { GrippSelect, GrippInput } from '../../styles/global';

const { Option } = GrippSelect;

const CreditRequestForm = ({
  creditRequestForm,
  isMobile,
  setIsValid,
  setAmount,
  setPlan,
  setInstallment
}: CreditRequestFormProps): JSX.Element => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [plans, setPlans] = React.useState<any[]>([]);
  const [installments, setInstallments] = React.useState<any[]>([]);
  const [selectedInstallment, setSelectedInstallment] = React.useState<number>(0);
  const { orgInfo } = React.useContext(GrippUIContext);

  const values = Form.useWatch([], creditRequestForm);

  const onPlanChange = (value: any) => {
    if (value) {
      const installmentSelected = plans.filter((plan) => plan.Codigo === value)[0]?.CuotasPosibles;
      setPlan(value);
      setInstallments(installmentSelected);
      setSelectedInstallment(installmentSelected[0]);
      creditRequestForm.setFieldsValue({
        cuotas: installmentSelected[0]
      });
    } else {
      setInstallments([]);
      setSelectedInstallment(0);
    }
  };

  const onInstallmentChange = (value: any) => {
    setSelectedInstallment(value)
  };

  React.useEffect(() => {
    if (orgInfo) {
      setPlans(orgInfo?.LinCred);
    }
  }, [orgInfo]);

  React.useEffect(() => {
    setInstallment(selectedInstallment);
  }, [selectedInstallment]);

  React.useEffect(() => {
    creditRequestForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsValid(true);
      },
      () => {
        setIsValid(false);
      },
    );
  }, [values]);


  const renderContent = () => (
    <Form
      name="creditRequestForm"
      labelAlign="right"
      labelWrap
      layout="horizontal"
      form={creditRequestForm}
      disabled={isLoading}
    >

      <CustomerLoanInformationRow>
        <Form.Item name="plan" label="Plan" rules={[{ required: true, message: "Por favor, elija un plan" }]}>
          <GrippSelect
            placeholder="Tipos de planes"
            onChange={onPlanChange}
            allowClear
          >
            {plans.map((eachPlan: any, index: number) => (
              <React.Fragment key={`credit-plan-${eachPlan.Codigo}`}>
                <Option value={eachPlan.Codigo}>{eachPlan.Detalle}</Option>
              </React.Fragment>
            ))}
          </GrippSelect>
        </Form.Item>
      </CustomerLoanInformationRow>
      <CustomerLoanInformationRow>
        <Form.Item name="cuotas" label="Cuotas" rules={[{ required: true, message: "Por favor, elija una cantidad de cuotas" }]}>
          <GrippSelect
            placeholder="Cuotas"
            allowClear
            value={selectedInstallment}
            onChange={onInstallmentChange}
          >
            {installments?.map((eachInstallMent: any, index: number) => (
              <React.Fragment key={`credit-plan-${eachInstallMent}`}>
                <Option value={eachInstallMent}>{eachInstallMent}</Option>
              </React.Fragment>
            ))}
          </GrippSelect>
        </Form.Item>
      </CustomerLoanInformationRow>
      <Form.Item name="monto" label="Monto" rules={[{ required: true, message: "Por favor, elija un monto" }]}>
        <GrippInput
          min={0}
          placeholder="Ingrese el monto"
          type="number"
          onChange={({ target: { value: newAmount } }) => { setAmount(Number(newAmount)); }}
        />
      </Form.Item>
    </Form>
  );

  const renderDesktop = () => (
    <Card
      bordered={false}
      style={{
        minWidth: 500,
        backgroundColor: "rgba(0, 0, 0, 0.02)",
        border: "1px dashed rgb(217, 217, 217)"
      }}
    >
      {renderContent()}
    </Card>
  );

  return (
    <>
      {isMobile ? renderContent() : renderDesktop()}
    </>
  );
};

export default CreditRequestForm;