import { Select } from "antd";
import styled from "styled-components";
import { PRIMARY_ORANGE } from "../../shared/constants/colors";

interface isMobileProp {
    isMobile: boolean;
};

export const CustomerLoanInformationColumn = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const NewCustomerColumn = styled.div<isMobileProp>`
    display: flex;
    flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'}; 
`;

