import { SendNotificationParams } from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    isDesktop: false,
    isEditMode: false,
    simulationEnabled: false,
    orgInfo: null,
    logOut: () => { },
    closeEditMode: () => { },
    sendNotification: ({ title = '', description = '' }: SendNotificationParams) => { },
    enableCreditSimulator: () => { },
    setLoginInfo: (loginInfo: any) => { },
};