import React from "react";
import { Button, Tooltip, message } from "antd";
import { StepActionWrapper, StepButtonWrapper } from "./styles";
import { StepsProps } from "./types";

const StepActions = ({ isMobile, shouldDisableNextStep, steps, current, onLastClick, next, prev }: StepsProps): JSX.Element => (
    <StepActionWrapper isMobile={isMobile}>
        <StepButtonWrapper>
            <Button disabled={current <= 0} style={{ margin: '0 8px' }} onClick={() => prev()}>
                Atras
            </Button>
            {current < steps.length - 1 && (
                <Tooltip title={shouldDisableNextStep && 'Por favor revise los campos requeridos.'}>
                    <Button
                        disabled={shouldDisableNextStep}
                        onClick={() => next()}
                        style={{ margin: '0 8px' }}
                        type="primary"
                    >
                        Siguiente
                    </Button>
                </Tooltip>
            )}
            {current === steps.length - 1 && (
                <Button type="primary" onClick={onLastClick}>
                    Solicitar crédito
                </Button>
            )}
        </StepButtonWrapper>
    </StepActionWrapper >
);

export default StepActions;