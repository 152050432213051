import React from 'react';
import { Drawer } from 'antd';
import { GrippContent } from '../../shared/Layout/styles';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import CreditSimulator from '../CreditSimulator';

const SideDrawer = (): JSX.Element => {
    const { isEditMode, simulationEnabled, closeEditMode } = React.useContext(GrippUIContext);

    return (
        <Drawer
            placement="right"
            closable
            onClose={() => closeEditMode()}
            open={isEditMode}
            key="right"
            title="Simulador de crédito"
        >
            <GrippContent $isMobile={false} style={{ paddingTop: 0, paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                {simulationEnabled && <CreditSimulator />}
            </GrippContent>
        </Drawer>
    )
};

export default SideDrawer;