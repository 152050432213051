import React from 'react';
import { Drawer } from 'antd';
import { GrippContent } from '../../shared/Layout/styles';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import CreditSimulator from '../CreditSimulator';

const BottomSheet = (): JSX.Element => {
    const { isEditMode, simulationEnabled, closeEditMode } = React.useContext(GrippUIContext);

    return (
        <Drawer
            placement="bottom"
            closable
            onClose={() => closeEditMode()}
            open={isEditMode}
            key="bottomSheet"
            title="Simulador de crédito"
            size='large'
        >
            <GrippContent $isMobile style={{ paddingTop: 0, paddingLeft: '2.5em', paddingRight: '2.5em' }}>
                {simulationEnabled && <CreditSimulator />}
            </GrippContent>
        </Drawer>
    );
};

export default BottomSheet;