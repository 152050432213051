import { Card } from 'antd';
import styled from 'styled-components';

interface isMobileProp {
    isMobile: boolean;
};

interface ContentWrapperProps {
    shouldFullWidth?: boolean;
};

export const LoanTitle = styled.h1`
    margin: 12px 0;
`;

export const LoanWrapper = styled.div<isMobileProp>`
    flex-direction: ${({ isMobile }) => isMobile ? 'row' : 'column'}; 
    ${({ isMobile }) => isMobile
        ? 'margin-top: 25px;'
        : ''
    }
    border-radius: 8px;
    display: flex;
    overflow: scroll;
`;

export const StepContentWrapper = styled.div<isMobileProp>`
    display: flex;
    justify-content: center;
    max-width: ${({ isMobile }) => isMobile ? '100%' : '75%'}; 
    margin: 8px auto;
`;

export const StepButtonWrapper = styled.div`
    margin-top: 24px;
`;

export const StepActionWrapper = styled.div<isMobileProp>`
    display: flex;
    justify-content: center;
`;

export const ContentWrapper = styled(Card)<ContentWrapperProps>`
    line-height: 260px;
    width: 100%;
    min-height: 395px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: ${({ shouldFullWidth }) => shouldFullWidth ? 'flex-start' : 'center'}; 
    &>.ant-card-body {
        padding: 0;
        ${({ shouldFullWidth }) => shouldFullWidth
        ? 'width: 100%;'
        : ''
    }
    }
`;

export const CustomerLoanInformationColumn = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CustomerLoanInformationRow = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FinancialStatusCard = styled(Card)<any>`
    padding: 24px;
    margin: 16px;
    min-width: 350px;
    min-height: 205px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed rgb(217, 217, 217);
`;

