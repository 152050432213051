import axios from "axios";
import { BASE_URL, getReceipsByDateUrl, COMMON_HEADERS } from "../shared/constants/api";

interface GetReceipsByDateParams {
    data: any,
    callBack: (param: any) => any;
};

interface GetReceipsByDatePresetParams {
    datePreset: string,
    callBack: (param: any) => any;
};

export const getReceipsByData = async ({ data, callBack }: GetReceipsByDateParams) => {
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getReceipsByDateUrl}`,
                data,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};

export const getReceipsDatePreset = async ({ datePreset, callBack }: GetReceipsByDatePresetParams) => {
    const receipsFormData = new FormData();
    receipsFormData.append('busRecibosxFecha', datePreset);
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getReceipsByDateUrl}`,
                data: receipsFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};
