import React from 'react';
import { Modal, Spin } from 'antd';
import { LoadingInnerWrapper } from './styles';
import { startPolling, submitCredit } from '../../services/loan';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { CreditSubmitProps } from './types';
import { Input } from 'antd';

const { Search } = Input;
const SubmitCredit = ({
  customerInfo,
  guarantorInfo,
  installments,
  details,
  plan,
  amount,
  isOpen,
  onCancel
}: CreditSubmitProps): JSX.Element => {
  const { sendNotification } = React.useContext(GrippUIContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isPolling, setIsPolling] = React.useState<boolean>(false);

  const [authCode, setAuthCode] = React.useState<string>("");
  const [htmlMessage, setHtmlMessage] = React.useState<string>("");
  const [submitId, setSubmitId] = React.useState<string>("");

  const submitCallBack = (response: any) => {
    setIsLoading(false);
    setIsSubmitting(false);
    if (response.err.length) {
      sendNotification({ title: 'Error de consulta', description: response.err[0][1], type: 'error' });
      onCancel();
    } else {
      setHtmlMessage(response?.autoMensaje);
      setSubmitId(response?.lsid);
      startPollingProcess(response?.lsid);
    }
  };

  const pollingCallBack = (response: any) => {
    setIsLoading(false);
    setIsPolling(false);
    if (response) {
      if (response.err.length) {
        sendNotification({ title: 'Error de consulta', description: response.err[0][1], type: 'error' });
      };
      setTimeout(() => {
        startPollingProcess();
      }, 1500);
    } else {
      sendNotification({ title: 'Error de consulta', description: "Hubo un error al comunicarse con Gripp", type: 'error' });
    }
  };

  const startPollingProcess = (lsidCode?: string): void => {
    if (submitId || lsidCode) {
      if (!isPolling) {
        setIsPolling(true);
      }
      startPolling({
        lsId: lsidCode || submitId,
        callBack: pollingCallBack
      });
    }
  }

  React.useEffect(() => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitCredit({
        customerInfo,
        guarantorInfo,
        plan,
        installments,
        amount,
        details,
        callBack: submitCallBack
      });
    }
  }, []);

  const renderSubmitting = (): JSX.Element => (<Spin tip="Procesando crédito . . ." size="large">
    <LoadingInnerWrapper />
  </Spin>);

  const renderContactingGripp = (): JSX.Element => (<Spin tip="Contactando con gripp . . ." size="large">
    <LoadingInnerWrapper />
  </Spin>);

  const renderSubmittedLoan = (): JSX.Element => (
    <div>
      <h4>De tener un codigo de autorizacion, ingreselo</h4>
      <div>
        <Search
          placeholder="Código"
          disabled={isSubmitting || isPolling || isLoading}
          enterButton="Procesar"
          onChange={({ target: { value: authCode } }) => { setAuthCode(authCode); }}
          onSearch={() => { console.log({ authCode }); }}
        />
      </div>
      {htmlMessage && (
        <div>
          <h3>
            Detalles</h3>
          <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
        </div>
      )}
      {
        true && (
          <div>
            {renderContactingGripp()}
          </div>
        )
      }
    </div>);

  return (
    <Modal
      title="Solicitud de crédito"
      open={isOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={
        <></>
      }
    >
      <div>
        {isSubmitting ? renderSubmitting() : renderSubmittedLoan()}
      </div>
    </Modal>
  );
};

export default SubmitCredit;
