import { Input } from 'antd';
import styled from 'styled-components';
import { PRIMARY_ORANGE } from '../../shared/constants/colors';

interface GrippInputProps {
    isRightAligned?: boolean;
}

export const ReceiptsWrapper = styled.div``;
export const ReceiptsTableWrapper = styled.div`
    width: 100%;
    overflow: auto;
`;

export const GrippInput = styled(Input) <GrippInputProps>`
    border-radius: 35px;
    border-color: ${PRIMARY_ORANGE};
    margin-bottom: 0;
    width: ${({ isRightAligned }) => isRightAligned ? '60%' : '100%'}; 
    ${({ isRightAligned }) => isRightAligned
        ? 'float: right;text-align:right'
        : ''
    }
`;

export const ReceipsTitle = styled.h1`
    margin: 12px 0;
`;