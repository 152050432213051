import { Button, Tooltip } from 'antd';
import { FooterButtonsProps } from './types';

const FooterButtons = ({
    isLoading,
    onCancel,
    onSubmit,
    isValid
}: FooterButtonsProps): JSX.Element => {
    return (
        <div>
            <Button
                key="backButton"
                onClick={onCancel}
                style={{ marginRight: 16, marginTop: 16 }}
            >
                Cancelar
            </Button>
            <Tooltip title={!isValid && 'Por favor revise los campos requeridos.'}>
                <Button
                    key="submitButton"
                    type="primary"
                    loading={isLoading}
                    onClick={onSubmit}
                    disabled={!isValid}
                    style={{ marginRight: 16, marginTop: 16 }}
                >
                    Guardar
                </Button>
            </Tooltip>
        </div>
    );
};

export default FooterButtons;