import React from 'react';
import { emailRegex, namesRegex, numbersRegex, streetNameRegex } from '../../shared/constants/regex';
import { CustomerLoanInformationColumn } from '../../pages/Loan/styles';
import { Form } from 'antd';
import { getCustomerById } from '../../services/customer';
import { GuarantorFormProps, Profession } from '../../pages/Loan/types';
import NewCustomer from '../NewCustomer';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { GrippSelect, GrippInput } from '../../styles/global';

const { Option } = GrippSelect;

const GuarantorForm = ({ form, isMobile, setSelectedGuarantor }: GuarantorFormProps): JSX.Element => {
  const { orgInfo } = React.useContext(GrippUIContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [idNumber, setIdNumber] = React.useState<number | undefined>(undefined);
  const [enableNewCustomer, setEnableNewCustomer] = React.useState<boolean>(false);
  const [newCustomer, setNewCustomer] = React.useState<any | undefined>(undefined);
  const [professionsList, setProfessionsList] = React.useState<Profession[]>([]);

  const values = Form.useWatch([], form);

  const setFormWithValue = (newValue: any): void => {
    form.setFieldsValue(newValue);
  }

  const callBackFn = (response: any) => {
    setIsLoading(false);
    if (response.IdCliente) {
      setFormWithValue(response);
      setSelectedGuarantor(response);
    } else {
      setNewCustomer(form.getFieldsValue());
      setEnableNewCustomer(true);
    };
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (idNumber && idNumber?.toString().length > 7) {
        setIsLoading(true);
        getCustomerById({ id: Number(idNumber), callBack: callBackFn });
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [idNumber]);


  const onCreateNewCustomer = (newCustomerInfo: any) => {
    setEnableNewCustomer(false);
    getCustomerById({ id: Number(newCustomerInfo.id), callBack: callBackFn })
  };

  React.useEffect(() => {
    if (orgInfo?.professions) {
      setProfessionsList(orgInfo?.professions);
    }
  }, [orgInfo]);

  React.useEffect(() => {
    setSelectedGuarantor((oldValue: any)=> ({...oldValue, ...form.getFieldsValue(), ...{TxProfesion: professionsList.find((e)=> e.Codigo === form.getFieldsValue().Profesion)?.Detalle}}));
  }, [values]);

  return (
    <>
      <NewCustomer
        isMobile={isMobile}
        isOpen={enableNewCustomer}
        newCustomer={newCustomer}
        onSubmit={onCreateNewCustomer}
        onCancel={(info: any) => { setEnableNewCustomer(false); }}
      />

      <Form
        name="customerForm"
        labelAlign="right"
        labelWrap
        layout={isMobile ? 'vertical' : 'horizontal'}
        form={form}
        disabled={isLoading}
      >
        <CustomerLoanInformationColumn>
          <Form.Item
            label="DNI"
            name="NumeroDocumento"
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8, marginTop: 24 }}
            rules={[
              { message: 'Ingrese un número de identificacion válido' },
              { pattern: numbersRegex(), message: 'Número de identificacion inválido' }
            ]}
          >
            <GrippInput
              min={0}
              onChange={({ target: { value: newIdNumber } }) => { setIdNumber(Number(newIdNumber)); }}
              value={idNumber}
              placeholder="Ingrese su numero de identificacion"
              type="number"
            />
          </Form.Item>
          <Form.Item
            label="Nombre completo"
            name="Nombre"
            rules={[
              { message: 'Ingrese un nombre válido' },
              { pattern: namesRegex(), message: 'Nombre inválido' }
            ]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8, marginTop: 24 }}
          >
            <GrippInput placeholder="Ingrese su nombre completo" />
          </Form.Item>

        </CustomerLoanInformationColumn>
        <Form.Item
          label="Telefono"
          name="Particular"
          rules={[
            { message: 'Ingrese un teléfono válido' },
            { pattern: numbersRegex(), message: 'Teléfono inválido' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8 }}
        >
          <GrippInput placeholder="Ingrese su telefono" type="phone" />
        </Form.Item>
        <Form.Item
          label="Celular"
          name="Celular"
          rules={[
            { message: 'Ingrese un celular válido' },
            { pattern: numbersRegex(), message: 'Celular inválido' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <GrippInput placeholder="Ingrese su celular" type="phone" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="Mail"
          rules={[
            { message: 'Ingrese un email válido' },
            { pattern: emailRegex(), message: 'Email inválido' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8 }}
        >
          <GrippInput placeholder="Ingrese su email" type="email" />
        </Form.Item>
        <Form.Item
          label="Calle"
          name="DomicilioCalle"
          rules={[
            { message: 'Ingrese una calle válida' },
            { pattern: streetNameRegex(), message: 'Calle inválida' }
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <GrippInput placeholder="Ingrese su calle" />
        </Form.Item>
        <Form.Item
          label="Número"
          name="DomicilioNumero"
          rules={[
            { message: 'Por favor ingrese un número' },
          ]}
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8 }}
        >
          <GrippInput placeholder="Ingrese su numero" type="number" />
        </Form.Item>
        <Form.Item
          label="Dpto"
          name="DomicilioDpto"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <GrippInput placeholder="Ingrese su dpto" />
        </Form.Item>
        <Form.Item
          label="Barrio"
          name="Barrio"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: 8 }}
        >
          <GrippInput placeholder="Ingrese su barrio" />
        </Form.Item>
        <Form.Item
          label="Zona"
          name="Zona"
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <GrippInput placeholder="Ingrese su zona" />
        </Form.Item>
        <Form.Item name="Profesion" label="Profesión" rules={[
          { required: true, message: 'Por favor ingrese una profesión' },
        ]}>
          <GrippSelect
            placeholder="Por favor ingrese una profesión"
            allowClear
          >
            {professionsList.map((eachProfession: any, index: number) => (
              <React.Fragment key={`profession-${eachProfession.Codigo}`}>
                <Option value={eachProfession.Codigo}>{eachProfession.Detalle}</Option>
              </React.Fragment>
            ))}
          </GrippSelect>
        </Form.Item>
      </Form>
    </>
  );
};

export default GuarantorForm;