import { ColumnsType } from "antd/es/table";
import { RowLoanInstallment } from "../../provider/types";
import { AmountWrapper } from "./styles";

export const getTableColumns = (): ColumnsType<RowLoanInstallment> => [
    {
        title: 'Cuota',
        dataIndex: 'cuota',
        width: 200,
        key: 'cuota'
    },
    {
        title: 'Vencimiento',
        dataIndex: 'vence',
        width: 200,
        key: 'vence'
    },
    {
        title: 'Monto',
        dataIndex: 'valor',
        width: 200,
        key: 'valor',
        render: (importe) => <AmountWrapper>${new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </AmountWrapper>,
    },

];