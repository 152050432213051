import axios from "axios";
import { BASE_URL, getLoanUrl, COMMON_HEADERS } from "../shared/constants/api";
import { parseObjectArrayToString } from "../shared/utils/objectUtils";

interface SubmitCreditParams {
    customerInfo: any;
    guarantorInfo: any;
    installments: number;
    plan: string;
    details: any[];
    amount: number;
    callBack: (param: any) => any;
};

interface PollingParams {
    lsId: string;
    callBack: (param: any) => any;
};

export const submitCredit = async ({ customerInfo, guarantorInfo, plan, installments, amount, details, callBack }: SubmitCreditParams) => {
    const loanData = new FormData();
    Object.keys(customerInfo).forEach((eachKey) => {
        loanData.append(eachKey, customerInfo[eachKey]);
    });
    loanData.append('detalle', parseObjectArrayToString(details));
    loanData.append('tipodoc', '1');
    loanData.append('nrodoc', customerInfo.NumeroDocumento);
    loanData.append('Plan', plan);
    loanData.append('Cuotas', `${installments}`);
    loanData.append('Monto', `${amount}`);
    loanData.append('nomGarante', `${guarantorInfo.Nombre}`);
    loanData.append('Garante', `${guarantorInfo.CodigoNumerico}`);
    try {
        const { data } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getLoanUrl}?enjq=1&guarda=datoscliente&logSol=1`,
                data: loanData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};

export const startPolling = async ({ lsId, callBack }: PollingParams) => {
    const pollData = new FormData();
    pollData.append('lsId', lsId);
    pollData.append('pa', "1");
    pollData.append('lsusid', "");

    try {
        const { data } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getLoanUrl}?actcred=1`,
                data: pollData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};
