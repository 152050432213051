import NavBar from "../../components/NavBar";
import TopBar from "../../components/TopBar";
import useMediaQuery from "../../hooks/useMediaQuery";
import { NotFoundWrapper } from "./styles";

const NotFound = (): JSX.Element => {
    const isMobile = useMediaQuery(768);
    return (
        <>
            <TopBar isMobile={isMobile} />
            {!isMobile && <NavBar isMobile={false} />}
            <NotFoundWrapper> 404!!! </NotFoundWrapper>
            {isMobile && <NavBar isMobile />}
        </>
    );
};

export default NotFound;