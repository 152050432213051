import axios from "axios";
import { API_URL, BASE_URL, COMMON_HEADERS, LOAN_URL, createNewCustomerUrl, getCustomerByIdUrl } from "../shared/constants/api";

interface GetCustomerByIdParams {
    id: number;
    callBack: (param: any) => any;
};

interface CreateNewCustomerParams {
    customerData: any;
    callBack: (param: any) => any;
};

interface GetCustomerByNameParams {
    name: string;
    callBack: (param: any) => any;
};

export const getCustomerById = async ({ id, callBack }: GetCustomerByIdParams) => {
    try {
        const { data } = await axios.get(`${BASE_URL}${LOAN_URL}${getCustomerByIdUrl(id)}`);
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};

export const getCustomersByName = async ({ name, callBack }: GetCustomerByNameParams) => {
    const customerSearchFormData = new FormData();
    customerSearchFormData.append('busClteAlfa', name);
    try {
        const { data } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${API_URL}`,
                data: customerSearchFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};

export const createNewCustomer = async ({ customerData, callBack }: CreateNewCustomerParams) => {
    const customerFormData = new FormData();
    customerFormData.append('tipodoc', '1');
    customerFormData.append('TipoResponsable', '1');
    customerFormData.append('nrodoc', customerData.NumeroDocumento);
    Object.keys(customerData).forEach((key: string) => {
        customerFormData.append(key, customerData[key]);
    });
    try {
        const { data } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${LOAN_URL}${createNewCustomerUrl()}`,
                data: customerFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};

