import axios from "axios";
import { BASE_URL, getPayoutsByDateUrl, COMMON_HEADERS } from "../shared/constants/api";

interface GetPayoutsByDataParams {
    data: any,
    callBack: (param: any) => any;
};

interface GetSettlementsByDatePresetParams {
    datePreset: string,
    callBack: (param: any) => any;
};

export const getPayoutsByDataInfo = async ({ data, callBack }: GetPayoutsByDataParams) => {
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getPayoutsByDateUrl}`,
                data,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};

export const getPayoutsDatePreset = async ({ datePreset, callBack }: GetSettlementsByDatePresetParams) => {
    const payoutsFormData = new FormData();
    payoutsFormData.append('busRecxFecha', datePreset);
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getPayoutsByDateUrl}`,
                data: payoutsFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};
