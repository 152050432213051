import React from 'react';
import { FinancialStatusCard } from '../../pages/Loan/styles';
import { Statistic } from 'antd';
import { FinancialSituationProps } from '../../pages/Loan/types';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { DANGER, PRIMARY_BLUE_DARK, SUCCESS } from '../../shared/constants/colors';

const FinancialSituation = ({ customer, isMobile }: FinancialSituationProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  return (
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
      <FinancialStatusCard bordered={false}>
        <Statistic
          title="Deuda No Vencida"
          value={customer?.novencida || 'Sin datos'}
          precision={2}
          valueStyle={{ color: customer?.novencida ? SUCCESS : PRIMARY_BLUE_DARK }}
        />
      </FinancialStatusCard>
      <FinancialStatusCard bordered={false}>
        <Statistic
          title="Deuda Vencida"
          value={customer?.vencida || 'Sin datos'}
          precision={2}
          valueStyle={{ color: customer?.vencida ? DANGER : PRIMARY_BLUE_DARK }}
        />
      </FinancialStatusCard>
      <FinancialStatusCard bordered={false}>
        <Statistic
          title="Deuda Total"
          value={customer?.deudatotal || 'Sin datos'}
          precision={2}
          valueStyle={{ color: customer?.deudatotal ? SUCCESS : PRIMARY_BLUE_DARK }}
        />
      </FinancialStatusCard>
      <FinancialStatusCard bordered={false}>
        <Statistic
          title="Cuotas del Mes"
          value={customer?.cuotasmes || 'Sin datos'}
          precision={2}
          valueStyle={{ color: customer?.cuotasmes ? SUCCESS : PRIMARY_BLUE_DARK }}
        />
      </FinancialStatusCard>
      <FinancialStatusCard bordered={false}>
        <Statistic
          title="Cap. Crediticia"
          value={customer?.capcred || 'Sin datos'}
          precision={2}
          valueStyle={{ color: customer?.capcred ? SUCCESS : PRIMARY_BLUE_DARK }}
        />
      </FinancialStatusCard>
    </div>
  );
};

export default FinancialSituation;