import { Space, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { RowSettlement } from "../../provider/types"
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';

export const getTableColumns = (doPrintSettlement:any, doPrintReceipt:any): ColumnsType<RowSettlement> => [
    {
        title: 'Fecha Vence',
        dataIndex: 'txfecha',
        key: 'txfecha'
    },
    {
        title: 'Credito',
        dataIndex: 'nrocomp',
        align: "center",
        key: 'nrocomp'
    },
    {
        title: 'Cliente',
        colSpan: 2,
        dataIndex: 'codcli',
        align: "center",
        render: (value) => <Space>{value.toString().substring(4, 12)}</Space>,
        key: 'cliente'
    },
    {
        title: 'Cliente',
        colSpan: 0,
        dataIndex: 'Nombre',
        align: "left",
        key: 'cliente'
    },
    {
        title: 'Plan Y Cuotas',
        dataIndex: 'PlanYCuotas',
        key: 'PlanyCuotas',
    },
    {
        title: 'Importe',
        dataIndex: 'Monto',
        align: 'right',
        key: 'Monto',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>,
    },
    {
        title: 'Comision',
        dataIndex: 'ComisionComercio',
        align: 'right',
        key: 'ComisionComercio',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>,
    },
    {
        title: 'Gasto Adm.',
        dataIndex: 'GastosAdministrativos',
        key: 'GastosAdm',
        align: 'center',
        render: (importe => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>)
    },
    {
        title: 'Seguro',
        dataIndex: 'ImporteSeguro',
        key: 'ImpSeguro',
        render: (importe => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>)
    },
    {
        title: 'Liquidado',
        dataIndex: 'liquidado',
        align: 'right',
        key: 'liquidado',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2,
        }).format(importe)} </Space>,
    },
    {
        title: 'Opciones',
        dataIndex: 'nrocomp',
        render: (_: any, record: RowSettlement) => {
            return (
                <Space>
                    <Typography.Link onClick={() => doPrintSettlement(record)} style={{ marginRight: 8 }}>
                        <PrinterOutlined />
                    </Typography.Link> 
                        { (record.NumeroRecibo !== 0) && 
                            (<Typography.Link onClick={() => doPrintReceipt(record)} style={{ marginRight: 8 }}>
                                <EditOutlined />
                            </Typography.Link>)
                        }
                </Space>
            );
        }
    }
];