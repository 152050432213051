import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

interface isMobileProp {
    $isMobile: boolean;
}

export const GrippLayoutWrapper = styled(Layout)`
    padding-bottom: 63px;
`;

export const GrippBreadcrumbWrapper = styled.div`
    margin-top: 12px;
`;

export const SimulateButtonWrapper = styled.div<isMobileProp>`
    position: fixed;
    bottom: ${({ $isMobile }) => $isMobile ? '75px' : '45px'}; 
    right: ${({ $isMobile }) => $isMobile ? '35px' : '45px'}; 
`;

export const GrippContent = styled(Content)<isMobileProp>`
    padding: ${({ $isMobile }) => $isMobile ? '0 15px' : '0 50px'}; 
`;
