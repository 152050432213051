import React from 'react';
import { CreditSummaryProps } from './types';
import {
    CreditSummaryStatusCard,
    CreditSummaryWrapper,
    SimulationTableWrapper,
    SummaryInfo,
    SummaryInfoWrapper,
    SummaryTitle,
    SummaryTitleWrapper
} from './styles';
import { Table } from 'antd';
import { UserOutlined, DollarOutlined, EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import { getLoanSimulatorData } from '../../services/simulator';
import { getTableColumns } from './utils';
import { GrippUIContext } from '../../provider/GrippUIProvider';

const CreditSummary = ({ isMobile, customer, amount, planSelected, installmentsSelected, setLoanDetails }: CreditSummaryProps): JSX.Element => {
    const { sendNotification } = React.useContext(GrippUIContext);
    const [dataSource, setDataSource] = React.useState<any[]>([]);
    const [insurance, setInsurance] = React.useState<number>(0);
    const [sealed, setSealed] = React.useState<number>(0);

    const simulatorCallBack = (data: any) => {
        if (data.err) {
            sendNotification({ title: 'Error de consulta', description: data.txerr, type: 'error' });
        } else {
            setDataSource(data?.installments);
            setLoanDetails(data?.installments);
            const splitSubtitle = data?.subtitulo?.split(' | ');
            setInsurance(splitSubtitle[0]?.split(":")[1]);
            setSealed(splitSubtitle[1]?.split(":")[1]);
        }
    };

    const calculateEstimate = async () => {
        getLoanSimulatorData({
            plan: planSelected,
            installments: installmentsSelected,
            amount: amount,
            callBack: simulatorCallBack
        });
    };

    const formattedAmount = (amount: number): string => (new Intl.NumberFormat('es-AR', {
        maximumFractionDigits: 2, minimumFractionDigits: 2
    }).format(amount))

    React.useEffect(() => {
        calculateEstimate();
    }, []);

    return (
        <CreditSummaryWrapper isMobile={isMobile}>
            <CreditSummaryStatusCard bordered={true}>
                <SummaryTitleWrapper>
                    <SummaryTitle>Resumen Crédito</SummaryTitle>
                </SummaryTitleWrapper>
                <SummaryInfoWrapper>
                    <SummaryInfo><UserOutlined /> Tomador: {customer?.Nombre}</SummaryInfo>
                    <SummaryInfo><DollarOutlined /> Monto total: ${formattedAmount(amount)}</SummaryInfo>
                    <SummaryInfo><EditOutlined /> Seguro: ${formattedAmount(insurance)}</SummaryInfo>
                    <SummaryInfo><PaperClipOutlined /> Sellados: ${formattedAmount(sealed)}</SummaryInfo>
                </SummaryInfoWrapper>
            </CreditSummaryStatusCard>
            <SimulationTableWrapper isMobile={isMobile}>
                <Table size="large" loading={!dataSource?.length} dataSource={dataSource || []} columns={getTableColumns()} rowKey="nrocomp" pagination={{ pageSize: 5 }} />
            </SimulationTableWrapper>
        </CreditSummaryWrapper>
    )
};

export default CreditSummary;