import React from 'react';
import dayjs from 'dayjs';
import { PayoutsWrapper, PayoutsTableWrapper, PayoutsTitle } from './styles';
import { AutoComplete, Table, Space, Row, Col, Divider, Button } from 'antd';
import { RowPayout } from '../../provider/types';
import { getTableColumns } from './utils';
import { getPayoutsByDataInfo, getPayoutsDatePreset } from '../../services/payouts';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { GrippRange, GrippRangePresetWrapper } from '../../styles/global';
import { parseCustomerAsOption } from '../../shared/utils/customer';
import { getCustomersByName } from '../../services/customer';
import { tableLocale } from '../../shared/utils/table';
import useMediaQuery from '../../hooks/useMediaQuery';

const Payouts = (): JSX.Element => {
    const { sendNotification } = React.useContext(GrippUIContext);
    const isMobile = useMediaQuery(768);
    const [dataSource, setDataSource] = React.useState<any[]>([]);
    const [dates, setDates] = React.useState<any[]>([]);

    const [searchText, setSearchText] = React.useState('');

    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(undefined);
    const [selectedDatePreset, setSelectedDatePreset] = React.useState<string>("3");

    const [customerOptions, setCustomerOptions] = React.useState<{ value: string }[]>([]);
    const [customerVirtualList, setCustomerVirtualList] = React.useState<any[]>([])

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const cleanCustomerLists = (): void => {
        setCustomerVirtualList([]);
        setCustomerOptions([]);
    };

    const searchCallBack = (data: any) => {
        if (data.datos.length) {
            setCustomerVirtualList(data.datos);
            setCustomerOptions(parseCustomerAsOption(data.datos));
        } else {
            sendNotification({ title: 'Sin cobros', description: data.txerr, type: 'info' });
        }
    };

    const payoutCallback = (data: any) => {
        if (data.err === 0) {
            setDataSource(data.datos);
        } else {
            sendNotification({ title: 'Sin cobros', description: data.txerr, type: 'info' });
        }
    };

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchText && searchText.length > 3 && searchText !== selectedCustomer?.Nombre) {
                cleanCustomerLists();
                setIsLoading(true);
                getCustomersByName({ name: searchText, callBack: searchCallBack });
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchText]);

    React.useEffect(() => {
        if (dates && dates.length) {
            const from = dayjs(dates[1]).format('DD/MM/YYYY');
            const to = dayjs(dates[0]).format('DD/MM/YYYY');
            const payoutsFormData = new FormData();
            payoutsFormData.append('busRecxFecha', "5");
            payoutsFormData.append('fcdesde', to);
            payoutsFormData.append('fchasta', from);
            getPayoutsByDataInfo({
                data: payoutsFormData,
                callBack: payoutCallback
            })
        }
    }, [dates]);

    React.useEffect(() => {
        if (selectedCustomer) {
            setDataSource([]);
            const payoutsFormData = new FormData();
            payoutsFormData.append('busRecxDni', "1");
            payoutsFormData.append('dni', selectedCustomer);
            payoutsFormData.append('tipo', "1");
            getPayoutsByDataInfo({
                data: payoutsFormData,
                callBack: payoutCallback
            });
        }
    }, [selectedCustomer]);

    React.useEffect(() => {
        if (selectedDatePreset) {
            getPayoutsDatePreset({
                datePreset: selectedDatePreset,
                callBack: payoutCallback
            });
        }
    }, [selectedDatePreset]);

    const onChange = (data: string) => {
        setSearchText(data);
    };

    const onSelect = (selectedCustomer: any) => {
        setSelectedCustomer(customerVirtualList.find((el: any) => el.Nombre === selectedCustomer)?.CodigoNumerico);
    };

    const onDateChange = (dates: any) => {
        if (dates && dates.length) {
            setDates([
                new Date(dates[0]),
                new Date(dates[1])
            ]);
        }
    };

    const doPrintPayouts = (item: RowPayout) => { }

    const renderRangePresets = () => {
        return (
            <GrippRangePresetWrapper>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "1" ? "primary" : "default"} onClick={() => setSelectedDatePreset("1")}>Hoy</Button>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "2" ? "primary" : "default"} onClick={() => setSelectedDatePreset("2")}>Esta semana</Button>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "3" ? "primary" : "default"} onClick={() => setSelectedDatePreset("3")}>Este mes</Button>
            </GrippRangePresetWrapper>
        )
    };

    const renderRangePicker = () => (
        isMobile
            ? (
                <div>
                    {renderRangePresets()}
                </div>
            )
            : (
                <Col offset={6}>
                    <GrippRange
                        renderExtraFooter={renderRangePresets}
                        placeholder={["Fecha desde", "Fecha hasta"]}
                        onChange={onDateChange} />
                </Col>
            )
    );

    return (<PayoutsWrapper>
        {!isMobile && <PayoutsTitle>Cobranzas</PayoutsTitle>}
        <Row>
            <Col>
                <Space>
                    Buscar por cliente
                    <AutoComplete
                        options={customerOptions}
                        style={{ width: 200 }}
                        onChange={onChange}
                        onSelect={onSelect}
                        placeholder="DNI o nombre del cliente"
                    />
                </Space>
            </Col>
            {renderRangePicker()}
        </Row>
        <Divider />
        <PayoutsTableWrapper>
            <Table
                locale={tableLocale}
                dataSource={dataSource}
                columns={getTableColumns(doPrintPayouts)}
                rowKey="nrocomp"
            />
        </PayoutsTableWrapper>
    </PayoutsWrapper>)
};

export default Payouts;