
import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import { INITIAL_STATE } from './utils';
import { GrippUIContext as GrippUIContextType, SendNotificationParams } from './types';
import { notification } from 'antd';
import { getOrganizationInfo } from '../services/organization';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from "../shared/constants/api";

const NOTIFICATION_PLACEMENT = 'top';
export const GrippUIContext = React.createContext<GrippUIContextType>(INITIAL_STATE);

const GrippProvider = ({ children }: any) => {
    const isMobile = useMediaQuery(768);
    const isTablet = useMediaQuery(1024);
    const isDesktop = !isMobile && !isTablet;

    const [api, contextHolder] = notification.useNotification();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [orgInfo, setOrgInfo] = React.useState<any | undefined>(undefined);
    const [simulationEnabled, setSimulationEnabled] = React.useState<boolean>(false);

    const closeEditMode = (): boolean => {
        setSimulationEnabled(false);
        return true;
    };

    const setLoginInfo = (loginInfo: any): void => {
        setOrgInfo(loginInfo);
    };

    const sendNotification = ({ title: message, description, type }: SendNotificationParams) => {
        api[type || 'info']({
            message,
            description,
            placement: NOTIFICATION_PLACEMENT,
        });
    };

    const logOut = async () => {
        fetch(`${BASE_URL}${API_URL}?LogOut=1`)
            .then(response => response.json())
            .then(() => {
                window.location.href = '/';
            }).catch(() => sendNotification({ title: 'Error', description: 'Hubo un error al querer salir, espere un momento e intentelo nuevamente', type: 'error' }));
    };

    const enableCreditSimulator = (): void => {
        if (!simulationEnabled) {
            setSimulationEnabled(true);
        };
    };

    const editModeSelected = React.useMemo(() => {
        return (simulationEnabled);
    }, [simulationEnabled]);

    return (
        <GrippUIContext.Provider
            value={{
                isLoading,
                isDesktop,
                logOut,
                isEditMode: editModeSelected,
                simulationEnabled,
                orgInfo,
                closeEditMode,
                sendNotification,
                enableCreditSimulator,
                setLoginInfo
            }}
        >
            {contextHolder}
            {children}
        </GrippUIContext.Provider>
    );
};

export default GrippProvider;