import React from 'react';
import { CreditSimulatorWrapper, LoanTableWrapper, SubmitButton, AmountWrapper } from './styles';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { Form, Table } from 'antd';
import { getTableColumns } from './utils';
import { getLoanSimulatorData } from '../../services/simulator';
import { RowInstallment } from '../../provider/types';
import { GrippSelect, GrippInput } from '../../styles/global';

const { Option } = GrippSelect;

const CreditSimulator = (): JSX.Element => {
    const { orgInfo, sendNotification } = React.useContext(GrippUIContext);
    const [plans, setPlans] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [dataSource, setDataSource] = React.useState<any[]>([]);
    const [installments, setInstallments] = React.useState<any[]>([]);
    const [planSelected, setPlanSelected] = React.useState<string>('');
    const [installmentsSelected, setInstallmentsSelected] = React.useState<number>(0);
    const [amount, setAmount] = React.useState<number>(0);
    const [grandTotal, setGrandTotal] = React.useState<number>(0);

    const [form] = Form.useForm();

    const onPlanChange = (value: any) => {
        const planSelected = plans.find((plan) => plan.Codigo === value);
        setPlanSelected(planSelected.Codigo);
        setInstallments(planSelected.CuotasPosibles);
        setInstallmentsSelected(planSelected.CuotasPosibles[0]);
        form.setFieldsValue({
            cuotas: planSelected.CuotasPosibles[0]
        });
    };

    const onInstallmentsSelected = (value: any) => {
        setInstallmentsSelected(value);
    }

    React.useEffect(() => {
        if (orgInfo) {
            setPlans(orgInfo?.LinCred);
        }
    }, [orgInfo])

    const simulatorCallBack = (data: any) => {
        setIsLoading(false);
        if (data.err) {
            sendNotification({ title: 'Error de consulta', description: data.txerr, type: 'error' });
        } else {
            setDataSource(data.installments);
            let sum = 0;
            data.installments.forEach ( (a:RowInstallment) => sum += a.valor);
            setGrandTotal(sum);
        }
    }

    const calculateEstimate = async () => {
        setIsLoading(true);
        getLoanSimulatorData({
            plan: planSelected,
            installments: installmentsSelected,
            amount: amount,
            callBack: simulatorCallBack
        });
    }

    return (<CreditSimulatorWrapper>
        <Form
            name="creditSimulatorForm"
            labelAlign="right"
            labelWrap
            layout="horizontal"
            form={form}
        >

            <Form.Item name="plan" label="Plan" rules={[{ required: true, message: "Por favor, elija un plan" }]}>
                <GrippSelect
                    placeholder="Tipos de planes"
                    onChange={onPlanChange}
                    allowClear
                >
                    {plans.map((eachPlan: any, index: number) => (
                        <React.Fragment key={`credit-plan-${eachPlan.Codigo}`}>
                            <Option value={eachPlan.Codigo}>{eachPlan.Detalle}</Option>
                        </React.Fragment>
                    ))}
                </GrippSelect>
            </Form.Item>
            <Form.Item name="cuotas" label="Cuotas" rules={[{ required: true, message: "Por favor, elija la cantidad de cuotas" }]}>
                <GrippSelect
                    placeholder="Cuotas"
                    onChange={onInstallmentsSelected}
                    allowClear
                >
                    {installments.map((eachInstallMent: any, index: number) => (
                        <React.Fragment key={`credit-plan-${eachInstallMent}`}>
                            <Option value={eachInstallMent}>{eachInstallMent}</Option>
                        </React.Fragment>
                    ))}
                </GrippSelect>
            </Form.Item>
            <Form.Item name="amount" label="Monto" rules={[{ required: true, message: "Por favor, escriba el monto" }]}>
                <GrippInput
                    min={0}
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    placeholder="Ingrese el monto"
                    type="number"
                />
            </Form.Item>
            <SubmitButton
                disabled={
                    !planSelected ||
                    !installmentsSelected ||
                    !amount
                }
                loading={isLoading}
                type="primary"
                onClick={calculateEstimate}>
                Simular
            </SubmitButton>
            {!!dataSource?.length &&
                (
                    <LoanTableWrapper>
                        <Table 
                        loading={!dataSource?.length} 
                        dataSource={dataSource || []} 
                        columns={getTableColumns()} 
                        rowKey="nrocomp" 
                        pagination={false} 
                        summary={() => (
                            <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="right">
                                    <AmountWrapper>${new Intl.NumberFormat('es-AR', {
                                        maximumFractionDigits: 2, minimumFractionDigits: 2
                                        }).format(grandTotal)} 
                                    </AmountWrapper></Table.Summary.Cell>
                            </Table.Summary.Row>
                            </Table.Summary>
                        )}/>
                    </LoanTableWrapper>
                )}
        </Form>
    </CreditSimulatorWrapper>)
}
export default CreditSimulator;