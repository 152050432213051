import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const NavBarWrapper = styled.div``;
export const DesktopNavBarWrapper = styled.div`
    position: relative;
    z-index: 1;
    clear: both;
    width: 100%;
    background-color: orange;
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s;
    height: auto;
    overflow: visible;
`;

export const DesktopItemsWrapper = styled.ul`
    display: block;
    text-align: center;
    padding: 0 5%;
    overflow-x: auto;
    display: flex;
    justify-content: center;
    height: 45px;
    align-items: center;
    margin: 0;
    font-weight: 500;
    color: white;
`;

export const DesktopItem = styled.li`
    padding: 0;
    margin: 0;
    width: fit-content;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
`;

export const MobileNavBarWrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    display: grid;
    align-content: center;
    background-color: orange;
    width: 100%;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px;
    overflow-x: scroll;
`;

export const MobileItemsWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow-x: scroll;
    width: 100%;
    color: white;
    font-weight: 500;
    padding: 0;
`;

export const MobileItem = styled.li`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
`;

export const MenuLink = styled(NavLink) <any>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin: 0 1em;
    text-decoration: none;
    color: white;
`;

export const MenuName = styled.span`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
`;