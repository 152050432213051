import styled from 'styled-components';

export const GrippLogoImage = styled.img`
    height: 50px;
    pointer-events: none;
`;

export const GrippLogoImageWrapper = styled.div`
    height: 50px;
    pointer-events: none;
`;

export const GrippHeader = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #f0f0f0;
    borderRadius: 3px;
`;

export const GrippLogoWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const GrippExitButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;