import React from 'react';
import dayjs from 'dayjs';
import { ReceiptsWrapper, ReceiptsTableWrapper, ReceipsTitle } from './styles';
import { AutoComplete, Table, Space, Row, Col, Divider, Button } from 'antd';
import { RowReceipt } from '../../provider/types';
import { getReceipsByData } from '../../services/receips';
import { PaymentInfo } from './types';
import PaymentReceipt from './PaymentReceipt';
import { getTableColumns } from './utils';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { getCustomersByName } from '../../services/customer';
import { GrippRange, GrippRangePresetWrapper } from '../../styles/global';
import { parseCustomerAsOption } from '../../shared/utils/customer';
import { getPayoutsDatePreset } from '../../services/payouts';
import { tableLocale } from '../../shared/utils/table';
import useMediaQuery from '../../hooks/useMediaQuery';

const Receipts = (): JSX.Element => {
    const { sendNotification } = React.useContext(GrippUIContext);
    const isMobile = useMediaQuery(768);
    const [dataSource, setDataSource] = React.useState<any[]>([]);
    const [dates, setDates] = React.useState<any[]>([]);

    const [openReceipt, setOpenReceipt] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');

    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(undefined);
    const [selectedDatePreset, setSelectedDatePreset] = React.useState<string>("3");

    const [customerOptions, setCustomerOptions] = React.useState<{ value: string }[]>([]);
    const [customerVirtualList, setCustomerVirtualList] = React.useState<any[]>([]);

    const [selectedPayment, setSelectedPayment] = React.useState<PaymentInfo | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const cleanCustomerLists = (): void => {
        setCustomerVirtualList([]);
        setCustomerOptions([]);
    };

    const receiptsCallBack = (data: any) => {
        if (data.err === 0) {
            setDataSource(data.datos.map((item: RowReceipt): RowReceipt => {
                item.ImporteCuotaPura += item.ImporteCuotaSeguro;
                item.CuotaOriginal += item.ImporteCuotaSeguro;
                item.MontoCuota = item.ImporteCuotaPura;
                item.Saldo = (item.Cancelado === 1) ? 0 : item.MontoCuota - ((item.ImportePago) ? item.ImportePago : 0);
                return item;
            }));
        } else {
            sendNotification({ title: 'Sin recibos', description: data.txerr, type: 'info' });
        }
    };

    const searchCallBack = (response: any) => {
        if (response.datos.length) {
            setCustomerVirtualList(response.datos);
            setCustomerOptions(parseCustomerAsOption(response.datos));
        } else {
            sendNotification({ title: 'Error de consulta', description: response.txerr, type: 'error' });
        }
    };

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchText && searchText.length > 3 && searchText !== selectedCustomer?.Nombre) {
                cleanCustomerLists();
                setIsLoading(true);
                getCustomersByName({ name: searchText, callBack: searchCallBack });
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchText]);

    React.useEffect(() => {
        if (dates && dates.length) {
            const from = dayjs(dates[1]).format('DD/MM/YYYY');
            const to = dayjs(dates[0]).format('DD/MM/YYYY');
            const receiptsFormData = new FormData();
            receiptsFormData.append('busRecibosxFecha', "5");
            receiptsFormData.append('fcdesde', to);
            receiptsFormData.append('fchasta', from);
            getReceipsByData({
                data: receiptsFormData,
                callBack: receiptsCallBack
            });
        }
    }, [dates]);

    React.useEffect(() => {
        if (selectedCustomer) {
            const receiptsFormData = new FormData();
            receiptsFormData.append('busRecibosxDni', "1");
            receiptsFormData.append('dni', selectedCustomer);
            receiptsFormData.append('tipo', "1");
            getReceipsByData({
                data: receiptsFormData,
                callBack: receiptsCallBack
            });
        }
    }, [selectedCustomer]);

    React.useEffect(() => {
        if (selectedDatePreset) {
            const receiptsFormData = new FormData();
            receiptsFormData.append('busRecibosxFecha',selectedDatePreset);
            getReceipsByData({
                data: receiptsFormData,
                callBack: receiptsCallBack
            });
        }
    }, [selectedDatePreset]);

    const onChange = (data: string) => {
        setSearchText(data);
    };

    const onSelect = (selectedCustomer: any) => {
        setSelectedCustomer(customerVirtualList.find((el: any) => el.Nombre === selectedCustomer)?.CodigoNumerico);
    };

    const onDateChange = (dates: any) => {
        if (dates && dates.length) {
            setDates([
                new Date(dates[0]),
                new Date(dates[1])
            ]);
        }
    };

    const doCobrar = (que: RowReceipt) => {
        setSelectedPayment({
            name: que.Nombre,
            phone: Number(que.Telefono),
            mobile: Number(que.Celular),
            email: que.Mail,
            dueDate: que.txfecha,
            paymentDate: '',
            feeAmount: (que.CuotaOriginal - ((!que.ImportePago) ? 0 : que.ImportePago)),
            interests: ((!que.MontoCuota) ? 0 : que.MontoCuota) - que.CuotaOriginal,
            total: ((!que.MontoCuota) ? 0 : que.MontoCuota) - ((!que.ImportePago) ? 0 : que.ImportePago)
        })
        setOpenReceipt(true);
        return que;
    }

    const handleOk = (newPaymentInfo: any) => {
        setOpenReceipt(false);
    };

    const handleCancel = () => {
        setOpenReceipt(false);
    };

    const renderRangePresets = () => {
        return (
            <GrippRangePresetWrapper>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "1" ? "primary" : "default"} onClick={() => setSelectedDatePreset("1")}>Hoy</Button>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "2" ? "primary" : "default"} onClick={() => setSelectedDatePreset("2")}>Esta semana</Button>
                <Button style={{ margin: "0 4px" }} type={selectedDatePreset === "3" ? "primary" : "default"} onClick={() => setSelectedDatePreset("3")}>Este mes</Button>
            </GrippRangePresetWrapper>
        )
    };

    const renderRangePicker = () => (
        isMobile
            ? (
                <div>
                    {renderRangePresets()}
                </div>
            )
            : (
                <Col offset={6}>
                    <GrippRange
                        renderExtraFooter={renderRangePresets}
                        placeholder={["Fecha desde", "Fecha hasta"]}
                        onChange={onDateChange} />
                </Col>
            )
    );

    return (<ReceiptsWrapper>
        {!isMobile && <ReceipsTitle>Recibos</ReceipsTitle>}
        <Row>
            <Col>
                <Space>
                    Buscar por cliente
                    <AutoComplete
                        options={customerOptions}
                        style={{ width: 200 }}
                        onChange={onChange}
                        onSelect={onSelect}
                        placeholder="DNI o nombre del cliente"
                    />
                </Space>
            </Col>
            {renderRangePicker()}
        </Row>
        <Divider />
        <Row>
            <ReceiptsTableWrapper>
                <Table
                    locale={tableLocale}
                    dataSource={dataSource}
                    columns={getTableColumns(doCobrar)}
                    rowKey="nrocomp"
                />
            </ReceiptsTableWrapper>
        </Row>
        <PaymentReceipt
            isOpen={openReceipt}
            handleOk={handleOk}
            handleCancel={handleCancel}
            paymentInfo={selectedPayment}
        />
    </ReceiptsWrapper>)
};

export default Receipts;