import React from 'react';
import { Form, Steps } from 'antd';
import { ContentWrapper, LoanTitle, LoanWrapper, StepContentWrapper } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';
import CustomerForm from '../../components/Forms/CustomerForm';
import GuarantorForm from '../../components/Forms/GuarantorForm';
import CreditRequestForm from '../../components/Forms/CreditRequestForm';
import StepActions from './StepActions';
import FinancialSituation from '../../components/Forms/FinancialSituation';
import CreditSummary from '../../components/CreditSummary';
import SubmitCredit from '../../components/CreditSubmit';

const Loan = (): JSX.Element => {
    const isMobile = useMediaQuery(768);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [customerForm] = Form.useForm();
    const [guarantorForm] = Form.useForm();
    const [creditRequestForm] = Form.useForm();

    const [amount, setAmount] = React.useState<number>(0);
    const [selectedPlan, setSelectedPlan] = React.useState<string>("");
    const [selectedInstallment, setSelectedInstallment] = React.useState<number>(0);
    const [loanDetails, setLoanDetails] = React.useState<any[]>([]);

    const [isSubmitCreditOpen, setIsSubmitCreditOpen] = React.useState<boolean>(false);

    const [isCustomerFormValid, setIsCustomerFormValid] = React.useState<boolean>(false);
    const [isCreditRequestFormValid, setIsCreditRequestFormValid] = React.useState<boolean>(false);

    const [selectedCustomer, setSelectedCustomer] = React.useState<any | undefined>(undefined);
    const [selectedGuarantor, setSelectedGuarantor] = React.useState<any | undefined>(undefined);

    const [current, setCurrent] = React.useState(0);

    const steps = [
        {
            title: 'Cliente',
            content: <CustomerForm
                isMobile={isMobile}
                form={customerForm}
                setSelectedCustomer={setSelectedCustomer}
                setIsValid={setIsCustomerFormValid}
            />,
        },
        {
            title: 'Garante(Opcional)',
            content: <GuarantorForm
                isMobile={isMobile}
                form={guarantorForm}
                setSelectedGuarantor={setSelectedGuarantor}
            />,
        },
        {
            title: 'Solicitud',
            content: <CreditRequestForm
                isMobile={isMobile}
                creditRequestForm={creditRequestForm}
                setAmount={setAmount}
                setPlan={setSelectedPlan}
                setIsValid={setIsCreditRequestFormValid}
                setInstallment={setSelectedInstallment}
            />,
        },
        {
            title: 'Situacion Financiera',
            content: <FinancialSituation isMobile={isMobile} customer={selectedCustomer} />,
        },
        {
            title: 'Solicitar crédito',
            content: <CreditSummary isMobile={isMobile} customer={selectedCustomer} amount={amount} planSelected={selectedPlan} installmentsSelected={selectedInstallment} setLoanDetails={setLoanDetails} />,
        },
    ];

    const handleOnChange = (value: number) => {
        if (value === 0 || !shouldDisableNextStep[value]) {
            setCurrent(value);
        };
    };

    const onLastClick = () => {
        setIsSubmitCreditOpen(true);
    };

    const next = () => { setCurrent(current + 1); };
    const prev = () => { setCurrent(current - 1); };
    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const shouldDisableNextStep: any = {
        "0": !isCustomerFormValid,
        "1": !isCustomerFormValid,
        "2": !isCreditRequestFormValid,
        "3": !isCustomerFormValid || !isCreditRequestFormValid,
        "4": !isCustomerFormValid || !isCreditRequestFormValid,
    };

    return (
        <>
            {isSubmitCreditOpen
                && <SubmitCredit
                    customerInfo={selectedCustomer}
                    guarantorInfo={selectedGuarantor}
                    installments={selectedInstallment}
                    plan={selectedPlan}
                    amount={amount}
                    details={loanDetails}
                    isOpen={isSubmitCreditOpen}
                    onCancel={() => { setIsSubmitCreditOpen(false); }}
                />}
            <LoanWrapper isMobile={isMobile}>
                {!isMobile && <LoanTitle>Nueva solicitud de crédito</LoanTitle>}
                <Steps type={isMobile ? "inline" : "navigation"} size="small" current={current} items={items} direction="horizontal" onChange={handleOnChange} />
            </LoanWrapper>
            <StepContentWrapper isMobile={isMobile}>
                <ContentWrapper
                    {...(
                        current === 4 && { shouldFullWidth: true }
                    )}
                >
                    {steps[current].content}
                </ContentWrapper>
            </StepContentWrapper >
            <StepActions
                isMobile={isMobile}
                shouldDisableNextStep={shouldDisableNextStep[current]}
                steps={steps}
                current={current}
                next={next}
                prev={prev}
                onLastClick={onLastClick}
            />
        </>
    )
};

export default Loan;
