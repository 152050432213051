import { NavLinkProps } from 'react-router-dom';
import { MenuLink, MenuName, MobileItem, MobileItemsWrapper, MobileNavBarWrapper } from './styles';

const MobileNavBar = (): JSX.Element => (
    <MobileNavBarWrapper>
        <MobileItemsWrapper>
            <MobileItem>
                <MenuLink
                    to="/solicitud">
                    <MenuName>
                        Nueva Solicitud
                    </MenuName>
                </MenuLink>
            </MobileItem>
            <MobileItem>
                <MenuLink
                    to="/recibos">
                    <MenuName>
                        Emitir Recibos
                    </MenuName>
                </MenuLink>
            </MobileItem>
            <MobileItem>
                <MenuLink
                    to="/liquidaciones">
                    <MenuName>
                        Consultar Liquidaciones
                    </MenuName>
                </MenuLink>
            </MobileItem>
            <MobileItem>
                <MenuLink
                    to="/cobranzas">
                    <MenuName>
                        Consultar Cobranzas
                    </MenuName>
                </MenuLink>
            </MobileItem>
        </MobileItemsWrapper>
    </MobileNavBarWrapper>
);

export default MobileNavBar;

