import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { DollarOutlined } from '@ant-design/icons';
import { animated, useSpring } from 'react-spring';
import { GrippContent, GrippBreadcrumbWrapper, GrippLayoutWrapper, SimulateButtonWrapper } from './styles';
import TopBar from '../../components/TopBar';
import useMediaQuery from '../../hooks/useMediaQuery';
import NavBar from '../../components/NavBar';
import { Breadcrumb, Button, Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LinkTitleMap } from '../../navigation/router';
import BottomSheet from '../../components/BottomSheet';
import SideDrawer from '../../components/SideDrawer';
import { GrippUIContext } from '../../provider/GrippUIProvider';

const GrippLayout = ({ children }: any): JSX.Element => {
    const { orgInfo, logOut, enableCreditSimulator } = React.useContext(GrippUIContext);
    const isMobile = useMediaQuery(768);
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);

    const animatedOpacity = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    });


    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return {
            title: <Link to={url}>{LinkTitleMap[url]}</Link>,
            key: url,
        };
    });

    const breadcrumbItems = [
        {
            title: <Link to="/">Inicio</Link>,
            key: 'home',
        },
    ].concat(extraBreadcrumbItems);

    React.useEffect(() => {
        if (!orgInfo) {
            logOut();
        }
    }, [orgInfo])

    return (
        <GrippLayoutWrapper className="layout">
            <TopBar isMobile={isMobile} />
            {!isMobile && <NavBar isMobile={false} />}
            {!isMobile && <SideDrawer />}
            {isMobile && <BottomSheet />}
            <GrippContent $isMobile={isMobile}>
                <animated.div style={animatedOpacity}>
                    <GrippBreadcrumbWrapper>
                        <Breadcrumb items={breadcrumbItems} />
                    </GrippBreadcrumbWrapper>
                    <div className="site-layout-content">
                        {children}
                        <SimulateButtonWrapper $isMobile={isMobile}>
                            <Tooltip title="Simular">
                                <Button
                                    icon={<DollarOutlined />}
                                    type="primary"
                                    shape="circle"
                                    onClick={enableCreditSimulator}>

                                </Button>
                            </Tooltip>
                        </SimulateButtonWrapper>
                    </div>
                </animated.div>
            </GrippContent>
            {isMobile &&
                <Footer style={{ textAlign: 'center' }}>
                    <NavBar isMobile />
                </Footer>
            }
        </GrippLayoutWrapper>)
};

export default GrippLayout;