export const BASE_URL = '';
export const API_URL = 'http://gripp1.selfip.com/s/grmacros.php';
export const LOAN_URL = 'http://gripp1.selfip.com/s/';

export const COMMON_HEADERS = {
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8'
};

export const getCustomerByIdUrl = (id: number): string => `grmacros.php?traeDni=1&dni=${id}&tipo=1`;

export const createNewCustomerUrl = (): string => `grmacros.php?enjq=1&guarda=datoscliente&trae=1`;

export const getOrganizationInfoUrl = `${LOAN_URL}grmacros.php?Defectos=1`;

export const getReceipsByDateUrl = `${LOAN_URL}grmacros.php`;

export const getSettlementsByDateUrl = `${LOAN_URL}grmacros.php`;

export const getLoanUrl = `${LOAN_URL}grmacros.php`;

export const getPayoutsByDateUrl = `${LOAN_URL}grmacros.php`;
