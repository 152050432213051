import { Space, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { RowPayout } from "../../provider/types"
import { EditOutlined, PrinterOutlined } from '@ant-design/icons';

export const getTableColumns = (doPrintPayouts:any): ColumnsType<RowPayout> => [
    {
        title: 'Fecha Vence',
        dataIndex: 'txfecha',
        key: 'txfecha'
    },
    {
        title: 'Recibo',
        dataIndex: 'nrocomp',
        align: "center",
        key: 'nrocomp'
    },
    {
        title: 'Cliente',
        colSpan: 2,
        dataIndex: 'codcli',
        align: "center",
        render: (value) => <Space>{value.toString().substring(4, 12)}</Space>,
        key: 'cliente'
    },
    {
        title: 'Cliente',
        colSpan: 0,
        dataIndex: 'Nombre',
        align: "left",
        key: 'cliente'
    },
    {
        title: 'A Pagar',
        dataIndex: 'Monto',
        align: 'right',
        key: 'Monto',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2,
        }).format(importe)} </Space>,
    },
    {
        title: 'Opciones',
        dataIndex: 'nrocomp',
        render: (_: any, record: RowPayout) => {
            return (
                <Space>
                    <Typography.Link onClick={() => doPrintPayouts(record)} style={{ marginRight: 8 }}>
                        <PrinterOutlined />
                    </Typography.Link> 
                </Space>
            );
        }
    }
];