import axios from "axios";
import { BASE_URL, getSettlementsByDateUrl, COMMON_HEADERS } from "../shared/constants/api";

interface GetSettlementsByDataParams {
    data: any,
    callBack: (param: any) => any;
};

interface GetSettlementsByDatePresetParams {
    datePreset: string,
    callBack: (param: any) => any;
};

export const getSettlementsByData = async ({ data, callBack }: GetSettlementsByDataParams) => {
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getSettlementsByDateUrl}`,
                data,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};

export const getSettlementsDatePreset = async ({ datePreset, callBack }: GetSettlementsByDatePresetParams) => {
    const settlementsFormData = new FormData();
    settlementsFormData.append('busLiqxFecha', datePreset);
    try {
        const { data: infoData } = await axios(
            {
                method: 'post',
                url: `${BASE_URL}${getSettlementsByDateUrl}`,
                data: settlementsFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(infoData);
    }
    catch (error) {
        console.error(error);
    }
};
