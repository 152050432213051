import { Space, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import { RowReceipt } from "../../provider/types"

export const getTableColumns = (doCobrar: any): ColumnsType<RowReceipt> => [
    {
        title: 'Fecha Vence',
        dataIndex: 'txfecha',
        key: 'txfecha'
    },
    {
        title: 'Credito',
        dataIndex: 'nrocomp',
        align: "center",
        key: 'nrocomp'
    },
    {
        title: 'C.',
        dataIndex: 'cuota',
        align: "center",
        key: 'cuota'
    },
    {
        title: 'Cliente',
        dataIndex: 'Nombre',
        key: 'Nombre'
    },
    {
        title: 'Monto Cr.',
        dataIndex: 'Monto',
        align: 'right',
        key: 'Monto',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>,
    },
    {
        title: 'Monto Cuota',
        dataIndex: 'MontoCuota',
        align: 'right',
        key: 'MontoCuota',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </Space>,
    },
    {
        title: 'Saldo',
        dataIndex: 'Saldo',
        align: 'right',
        key: 'Saldo',
        render: (importe) => <Space>{new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2,
        }).format(importe)} </Space>,
    },
    {
        title: 'Opciones',
        dataIndex: 'nrocomp',
        render: (_: any, record: RowReceipt) => {
            return (<Typography.Link onClick={() => doCobrar(record)} style={{ marginRight: 8 }}>
                Cobrar
            </Typography.Link>);
        }
    }
];
