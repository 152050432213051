import React, { useEffect, useState } from 'react';
import { NavBarProps } from './types';
import MobileNavBar from './mobileNav';
import DesktopNavBar from './desktopNav';

const NavBar = ({ isMobile }: NavBarProps): JSX.Element => {

    return (
        isMobile
            ? <MobileNavBar />
            : <DesktopNavBar />
    )
};

export default NavBar;

