import React from 'react';
import { TopBarProps } from './types';
import { GrippExitButtonWrapper, GrippHeader, GrippLogoImage, GrippLogoImageWrapper, GrippLogoWrapper } from './styles';
import GrippLogo from '../../assets/logos/logogripp.png';
import { Button, Tooltip } from 'antd';
import { GrippUIContext } from '../../provider/GrippUIProvider';

const TopBar = ({ isMobile }: TopBarProps): JSX.Element => {
    const { logOut } = React.useContext(GrippUIContext);

    return (
        <GrippHeader>
            <GrippLogoWrapper>
                <GrippLogoImageWrapper>
                    <GrippLogoImage src={GrippLogo} alt="logo" />
                </GrippLogoImageWrapper>
            </GrippLogoWrapper>
            <GrippExitButtonWrapper>
                <Tooltip title="Salir">
                    <Button onClick={() => logOut()} >Salir</Button>
                </Tooltip>
            </GrippExitButtonWrapper>
        </GrippHeader>

    );
};

export default TopBar;