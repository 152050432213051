import styled from "styled-components";
import { Input, DatePicker, Select } from "antd";
import { PRIMARY_ORANGE } from "../shared/constants/colors";
const { RangePicker } = DatePicker;

export const GrippInput = styled(Input)`
    border-radius: 35px;
    border-color: ${PRIMARY_ORANGE};
    margin: 0;
    width: 100%;
    &.ant-input-status-success{
        border-color: green;
    }    
`;


export const GrippRight = styled(Input)`
    border-radius: 35px;
    border-color: ${PRIMARY_ORANGE};
    margin-bottom: 0;
    float:right;
    width: 60%;
`;

export const GrippRange = styled(RangePicker)`
    border-radius: 35px;
    border-color: ${PRIMARY_ORANGE};
    margin-bottom: 0;
    width: 100%;
`;

export const GrippSelect = styled(Select)`
    min-width: 150px;
    margin: 0;
    & > .ant-select-selector {
        border-radius: 35px !important;
        border-color: ${PRIMARY_ORANGE} !important;
        width: 100%;
        margin-bottom: 0;
    }
`;


export const GrippRangePresetWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 8px 0;
`;