import React from 'react';
import { GrippInput } from './styles';
import { Modal, Form } from 'antd';
import { emailRegex, namesRegex, numbersRegex } from '../../shared/constants/regex';
import useMediaQuery from '../../hooks/useMediaQuery';
import { PaymentReceiptProps } from './types';

const PaymentReceipt = ({ isOpen, paymentInfo, handleOk, handleCancel }: PaymentReceiptProps): JSX.Element => {
    const isMobile = useMediaQuery(768);
    const [form] = Form.useForm();

    const [name, setName] = React.useState<string>("nombre");
    const [phone, setPhone] = React.useState<number | undefined>(undefined);
    const [mobile, setMobile] = React.useState<number | undefined>(undefined);
    const [email, setEmail] = React.useState<string>("email");
    const [dueDate, setDueDate] = React.useState<string>("");
    const [paymentDate, setPaymentDate] = React.useState<string>("");
    const [feeAmount, setFeeAmount] = React.useState<number | undefined>(undefined);
    const [interests, setInterets] = React.useState<number | undefined>(undefined);
    const [total, setTotal] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (paymentInfo) {
            setName(paymentInfo?.name);
            setPhone(paymentInfo?.phone);
            setMobile(paymentInfo?.mobile);
            setEmail(paymentInfo?.email);
            setDueDate(paymentInfo?.dueDate);
            setPaymentDate(paymentInfo?.paymentDate);
            setFeeAmount(paymentInfo?.feeAmount);
            setInterets(paymentInfo?.interests);
            setTotal(paymentInfo?.total);
        };
    }, [paymentInfo]);

    return (
        <Modal
            title="Recibo de Pago"
            open={isOpen}
            onOk={() => handleOk({
                name,
                phone,
                mobile,
                email,
                dueDate,
                paymentDate,
                feeAmount,
                interests,
                total
            })}
            onCancel={handleCancel}
        >
            <Form
                name="receiptForm"
                labelAlign="right"
                labelWrap
                layout={isMobile ? 'vertical' : 'horizontal'}
                form={form}
            >
                <Form.Item
                    label="Name"
                    rules={[
                        { required: true, message: 'Nombre inválido' },
                        { pattern: namesRegex(), message: 'Nombre inválido' }
                    ]}
                >
                    <GrippInput onChange={({ target: { value: newName } }) => { setName(newName); }} value={name} placeholder="Nombre del Cliente" />
                </Form.Item>
                <Form.Item
                    label="Telefono"
                    rules={[
                        { required: true, message: 'Teléfono inválido' },
                        { pattern: numbersRegex(), message: 'Teléfono inválido' }
                    ]}
                >
                    <GrippInput onChange={({ target: { value: newPhone } }) => { setPhone(Number(newPhone)); }} value={phone} placeholder="Ingrese su telefono" />
                </Form.Item>
                <Form.Item
                    label="Celular"
                    rules={[
                        { required: true, message: 'Celular inválido' },
                        { pattern: numbersRegex(), message: 'Celular inválido' }
                    ]}
                >
                    <GrippInput onChange={({ target: { value: newMobile } }) => { setMobile(Number(newMobile)); }} value={mobile} placeholder="Ingrese su celular" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    rules={[
                        { required: true, message: 'Email inválido' },
                        { pattern: emailRegex(), message: 'Email inválido' }
                    ]}
                >
                    <GrippInput onChange={({ target: { value: newEmail } }) => { setEmail(newEmail); }} value={email} placeholder="Ingrese su email" type="email" />
                </Form.Item>
                <Form.Item
                    label="Fecha de Vencimiento"
                >
                    <GrippInput isRightAligned placeholder="Fecha de Vencimiento de la Cuota" value={dueDate} disabled />
                </Form.Item>
                <Form.Item
                    label="Fecha de Pago"
                >
                    <GrippInput isRightAligned placeholder="Fecha de pago" value={paymentDate} disabled />
                </Form.Item>
                <Form.Item
                    label="Saldo de Cuota"
                >
                    <GrippInput isRightAligned placeholder="Saldo a pagar" value={feeAmount} disabled />
                </Form.Item>
                <Form.Item
                    label="Interes"
                >
                    <GrippInput isRightAligned placeholder="Intereses" value={interests} disabled />
                </Form.Item>
                <Form.Item
                    label="Total"
                >
                    <GrippInput isRightAligned  onChange={({ target: { value: newTotal } }) => { setTotal(Number(newTotal)); }} placeholder="Total a Pagar" value={total} />
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default PaymentReceipt;
