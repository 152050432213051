import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Login from "../pages/Login";
import Loan from "../pages/Loan";
import NotFound from "../pages/NotFound";
import GrippLayout from "../shared/Layout";
import Receipts from "../pages/Receipts";
import Settlements from "../pages/Settlements";
import Payouts from "../pages/Payouts";
// Insert new import here - Please do not remove this line

export const LinkTitleMap: Record<string, string> = {
  '/login': 'Login',
  '/solicitud': 'Solicitud',
  '/recibos': 'Recibos',
  '/liquidaciones': 'Liquidaciones',
  '/cobranzas': 'Cobranzas'
};

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      path="/"
      element={<Login />}
    />,
    <Route path="/login" element={<Login />} />,
    <Route index path="/solicitud" element={<GrippLayout><Loan /></GrippLayout>} />,
    <Route path="/recibos" element={<GrippLayout><Receipts /></GrippLayout>} />,
    <Route path="/liquidaciones" element={<GrippLayout><Settlements /></GrippLayout>} />,
    <Route path="/cobranzas" element={<GrippLayout><Payouts /></GrippLayout>}/>,
    <Route path="*" element={<GrippLayout><NotFound /></GrippLayout>} />,
  /* Add new pages here - Please do not remove this line */
  ])
);

export default router;