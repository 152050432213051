import { Card } from 'antd';
import styled from 'styled-components';

interface isMobileProp {
    isMobile: boolean;
}

export const CreditSummaryWrapper = styled.section<isMobileProp>`
    display: flex;
    flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'}; 
    justify-content: space-between;
    width: 100%;
    min-width: 350px;
`;

export const SummaryTitleWrapper = styled.div`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed rgb(217, 217, 217);
    padding: 8px;
    text-align: center;
`;

export const SummaryInfoWrapper = styled.div`
    padding: 0 24px;
`;

export const SummaryTitle = styled.h1`
    margin: 0;
`;

export const SummaryInfo = styled.h3``;

export const CreditSummaryStatusCard = styled(Card)<any>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px;
    margin: 0 8px;
    width: ${({ isMobile }) => isMobile ? '100%' : '65%'}; 
    min-width: 350px;
    min-height: 205px;
    text-align: left;
    &>.ant-card-body {
        padding: 0;
    }
`;

export const SimulationTableWrapper = styled.div<isMobileProp>`
    width: 100%;
    overflow: auto;
    ${({ isMobile }) => isMobile
        ? 'margin-top: 25px;'
        : ''
    }
`;

export const AmountWrapper = styled.div`
    text-align: right;
`;
