import axios from "axios";
import { API_URL, BASE_URL, COMMON_HEADERS } from "../shared/constants/api";

interface LoginParams {
    user: string,
    password: string,
    callBack: (param: any) => any;
};

export const loginApi = async ({ user, password, callBack }: LoginParams) => {
    const loginFormData = new FormData();
    loginFormData.append('Login', '1');
    loginFormData.append('usuario', user);
    loginFormData.append('contra', password);
    loginFormData.append('Defectos', '1');
    try {
        const { data } = await axios(

            {
                method: 'post',
                url: `${BASE_URL}${API_URL}?Defectos=1`,
                data: loginFormData,
                headers: {
                    ...COMMON_HEADERS
                }
            });
        callBack(data);
    }
    catch (error) {
        console.error(error);
    }
};
