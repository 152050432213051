import Table, { ColumnsType } from "antd/es/table";
import { RowLoanInstallment } from "../../provider/types"
import { AmountWrapper } from "./styles";
import { ReactNode } from "react";

export const getTableColumns = (): ColumnsType<RowLoanInstallment> => [
    {
        title: '#',
        dataIndex: 'cuota',
        key: 'cuota'
    },
    {
        title: 'Vence',
        dataIndex: 'vence',
        key: 'vence'
    },
    {
        title: 'Monto',
        dataIndex: 'valor',
        key: 'valor',
        render: (importe) => <AmountWrapper>${new Intl.NumberFormat('es-AR', {
            maximumFractionDigits: 2, minimumFractionDigits: 2
        }).format(importe)} </AmountWrapper>,
    },

];
