import { RouterProvider } from 'react-router-dom';
import { RootWrapper } from './styles';
import router from '../../navigation/router';
import GrippProvider from '../../provider/GrippUIProvider';
import { ConfigProvider } from 'antd';
import GrippUIApp from '../GrippUIApp';

const Root = (): JSX.Element => {
    return (
        <RootWrapper>
            <GrippProvider>
                <ConfigProvider
                    theme={{ token: { colorPrimary: '#FFA503' } }}
                >
                    <GrippUIApp>
                        <RouterProvider router={router} />
                    </GrippUIApp>
                </ConfigProvider>
            </GrippProvider>
        </RootWrapper>
    );
};

export default Root;