import styled from 'styled-components';
import { Button } from 'antd';

export const CreditSimulatorWrapper = styled.div``;

export const CreditSimulatorTitle = styled.h2`
    margin: 12px 0;
`;

export const LoanTableWrapper = styled.div`
    width: 100%;
    overflow: auto;
    margin-top: 24px;
`;

export const SubmitButton = styled(Button)`
width: 100%;
`;

export const AmountWrapper = styled.div`
    text-align: right;
`;