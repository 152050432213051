import { useNavigate } from 'react-router-dom';
import '../../styles/login.css';
import { animated, useSpring } from 'react-spring';
import { Form, Input, Button, Row, Space, Image } from 'antd';
import React from 'react';
import { GrippUIContext } from '../../provider/GrippUIProvider';
import { loginApi } from '../../services/login';
import { getOrganizationInfo } from '../../services/organization';
import { CreditLine, CustomerParameters } from '../../provider/types';

const Login = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [userName, setUserName] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const animatedOpacity = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    });

    const { setLoginInfo, sendNotification } = React.useContext(GrippUIContext);

    const loginCallBack = (response: any) => {
        setIsLoading(false);
        if (response?.error === 1 || response?.err === 1) {
            sendNotification({
                title: 'Error',
                description: 'Error al querer ingresar al sistema, revise sus credenciales y pruebe nuevamente.',
                type: 'error'
            });
        } else {
            if (response && response.par) {
                response.par.forEach( (parameter:CustomerParameters ) => {
                    switch (parameter.Parametro) {
                        case 1:
                            (!response.taxCategories) ? response.taxCategories = [{Codigo: parameter.Codigo, Detalle:parameter.Detalle}] : response.taxCategories.push({Codigo: parameter.Codigo, Detalle: parameter.Detalle});
                        break;
                        case 10:
                            (!response.provinces) ? response.provinces = [{Codigo: parameter.Codigo, Detalle:parameter.Detalle}] : response.provinces.push({Codigo: parameter.Codigo, Detalle: parameter.Detalle});
                        break;                        
                        case 13:
                            (!response.professions) ? response.professions = [{Codigo: parameter.Codigo, Detalle:parameter.Detalle}] : response.professions.push({Codigo: parameter.Codigo, Detalle: parameter.Detalle});
                        break;
                    }
                });
                response.LinCred.forEach( (line:CreditLine ) => {
                    switch (line.Codigo) {
                        case 'A':
                        case 'B':
                        case 'C':
                        case 'G C':
                        case 'GC':
                        case 'GP':
                        case 'GR12':
                        case 'GR6':
                        case 'REFIN-12':
                        case 'REFIN-6':
                        case 'REFIN-3':
                            line.PrimerCuota=1;
                            line.CuotasPosibles = Array.from({length: (line.MaximoCuotas)}, (_, i) => i + 1);
                            break;
                        case 'G3':
                        case 'G3P':
                        case 'G3 Plus':
                        case 'G 3':
                        case 'GC3':
                        case 'GC 3':
                        case 'GRIPP 3':
                        case 'GRIPP3':
                            line.PrimerCuota=2;
                            line.CuotasPosibles = Array.from({length: (line.MaximoCuotas-1)}, (_, i) => i + 2);
                            break;
                        case 'D':
                        case 'GRIPP 6':
                        case 'GRIPP6':
                        case 'G6':
                        case 'G 6':
                        case 'GP6':
                            line.PrimerCuota=4;
                            line.CuotasPosibles = Array.from({length: (line.MaximoCuotas-3)}, (_, i) => i + 4);
                            break;
                        case 'GRIPP 12':
                        case 'GRIPP12':
                        case 'G12':
                        case 'G 12':
                            line.PrimerCuota=12;
                            line.CuotasPosibles = [12];
                            break;
                        case 'GA':
                        case 'GM':
                            line.PrimerCuota=12;
                            line.CuotasPosibles = [12,18,24,30,36];
                            break;
                    }
                });

            } else console.log(response);
            setLoginInfo(response);
            navigate("/solicitud");
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (userName && password) {
            setIsLoading(true);
            loginApi({ user: userName, password, callBack: loginCallBack });
        }
    };
    const checkIfLoggedCallBack = (isLoggedIn: any): void => {
        if (isLoggedIn.err === 0) {
            navigate("/solicitud");
        }
    };

    React.useEffect(() => {
        getOrganizationInfo({ callBack: checkIfLoggedCallBack });
    }, []);

    return (
        <animated.div style={animatedOpacity} className="login-page">
            <div className="login-box">
                <div className="illustration-wrapper">
                    <img src="http://gripp1.selfip.com/s/img/fondito2.jpg" alt="Login" />
                </div>
                <Form
                    name="login-form"
                    onSubmitCapture={handleSubmit}
                >
                    <Row justify="center">
                        <Space align="center" >
                            <Image preview={false} src="/logoGripp.png"></Image>
                        </Space>
                    </Row>
                    <p>
                        Somos una empresa de inversores privados y pampeanos, pero además somos una familia.
                        Perseguimos un sueño y trabajamos para lograrlo.<br />
                        Queremos ser una empresa <b> "debemos ser el cambio que buscamos en el mundo.
                            Dependemos uno del otro y como resultado somos responsables por nosotros mismos
                            y por las futuras generaciones"</b>
                    </p>
                    <Form.Item
                        rules={[{ required: true, message: 'Ingrese un nombre de usuario válido' }]}
                    >
                        <Input
                            placeholder="Ingrese su nombre"
                            disabled={isLoading}
                            value={userName}
                            onChange={({ target: { value: newUsername } }) => { setUserName(newUsername) }}
                            style={{ padding: '12px 15px' }}
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[{ required: true, message: 'Ingrese una contraseña válida' }]}
                    >
                        <Input.Password
                            type="password"
                            placeholder="Ingrese su contraseña"
                            disabled={isLoading}
                            value={password}
                            onChange={({ target: { value: newPassword } }) => { setPassword(newPassword) }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Ingresar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </animated.div>
    );
};

export default Login;