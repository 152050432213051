import { DesktopItem, DesktopItemsWrapper, DesktopNavBarWrapper, MenuLink, MenuName } from "./styles";

const DesktopNavBar = (): JSX.Element => (
    <DesktopNavBarWrapper>
        <DesktopItemsWrapper>
            <DesktopItem>
                <MenuLink
                    className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
                    to="/solicitud">
                    <MenuName>
                        Nueva Solicitud
                    </MenuName>
                </MenuLink>
            </DesktopItem>
            <DesktopItem>
                <MenuLink
                    className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
                    to="/recibos">
                    <MenuName>
                        Emitir Recibos
                    </MenuName>
                </MenuLink>
            </DesktopItem>
            <DesktopItem>
                <MenuLink
                    className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
                    to="/liquidaciones">
                    <MenuName>
                        Consultar Liquidaciones
                    </MenuName>
                </MenuLink>
            </DesktopItem>
            <DesktopItem>
                <MenuLink
                    className={({ isActive }: any) => (isActive ? 'active' : 'inactive')}
                    to="/cobranzas">
                    <MenuName>
                        Consultar Cobranzas
                    </MenuName>
                </MenuLink>
            </DesktopItem>
        </DesktopItemsWrapper>

    </DesktopNavBarWrapper>
);

export default DesktopNavBar;

